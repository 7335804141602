import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";
import LiveChat from "../global-components/live-chat";
import { Container } from "react-bootstrap-v5";

const navigation = [
  {
    id: 1,
    name: "Home",
    link: "/",
    icon: "fas fa-home",
  },
  {
    id: 2,
    name: "About",
    link: "/about",
    icon: "fas fa-user",
  },
  {
    id: 3,
    name: "Services",
    link: "/services",
    icon: "fas fa-cogs",
  },
  {
    id: 4,
    name: "Gallery",
    link: "/gallery",
    icon: "fas fa-briefcase",
  },
  {
    id: 5,
    name: "Contact",
    link: "/contact",
    icon: "fas fa-rss",
  },
];

const Footer = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <footer className="footer-area footer-area-2 bg-gray">
      <div className="footer-top2">
        <div className="container">
          <div className="row">
            <div className="col-md-4 align-self-center text-center">
            </div>
            <div className="col-md-4 align-self-center text-center">
                <div className="logo-footer">
                    <a href="index.html">
                      <img src={rpdata?.dbPrincipal?.logo} alt="img" />
                    </a>
                </div>
            </div>
            <div className="col-md-4 align-self-center text-center">     
            </div>
          </div>
        </div>
      </div>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-3 col-md-6">
              <div className="widget widget_about text-center">
                <a href="index.html">
                  <img src={rpdata?.dbPrincipal?.logo} alt="img" />
                </a>
                <div className="details">
                  <p>{rpdata.dbAbout?.[0].text.substring(0, 120) + ".."}</p>
                  <ul className="social-media">
                    {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={`${item.url}`} target="_blank">
                            <i
                              className={`fa fa-${item.icon}`}
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div> */}

          <div className="col-lg-3 col-md-6">
              <div className="widget widget_contact2">
                <h4 className="widget-title">About Us</h4>
                <div className="details">
                  <p>{rpdata.dbAbout?.[0].text.substring(0, 150) + ".."}</p>
                  <ul className="social-media">
                    {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={`${item.url}`} target="_blank" >
                                <i
                                className={`fa fa-${item.icon}`}
                                aria-hidden="true"
                                ></i>
                            </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            

            <div className="col-lg-3 col-md-6">
              <div className="widget widget_contact">
                <h4 className="widget-title">Navigation</h4>
                <ul className="details">
                  {navigation.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link to={item.link}>
                          <i className="fa fa-arrow-right" /> {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Contact</h4>
                <ul className="go-top">
                  <li>
                    <Link to="/contact">
                      {rpdata?.dbPrincipal?.location[0].address}
                    </Link>
                  </li>
                  {rpdata?.dbPrincipal?.phones.slice(0,1).map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={`tel:+1${item.phone}`}> {item.phone}</a>
                      </li>
                    );
                  })}
                  {rpdata?.dbPrincipal?.phones.slice(1,2).map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={`tel:+1${item.phone}`}> {item.phone}</a>
                      </li>
                    );
                  })}
                  <li>
                    <span>{rpdata?.dbPrincipal?.workdays[0].day}</span>
                  </li>
                  <li>
                    <span>{rpdata?.dbPrincipal?.workHours[0].hour}</span>
                  </li>
                  <li>
                    <span>{rpdata?.dbPrincipal?.paymentMethod}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="widget widget_blog_list">
                <h4 className="widget-title">Services</h4>
                <ul>
                  {rpdata?.dbServices?.slice(0, 5).map((item, index) => {
                    return (
                      <li key={index}>
                        <h6>
                          <Link to="/services">{item.name}</Link>
                        </h6>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

          </div>
        </div>
        <LiveChat/>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center text-center">
              <p className="text-white">Copyright 2022 by {rpdata?.dbPrincipal?.name}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
