import React from "react";
import { useEffect, useState } from "react";

export const ScrollTop = () => {
  
    const[ backToTopButtom, setBackToTopButton] = useState(false);

    useEffect(() =>{
        window.addEventListener("scroll", () =>{
            if(window.scrollY > 100){
                setBackToTopButton(true)
            } else{
                setBackToTopButton(false)
            }
        })
    }, [])
    
    const scrollUp = () =>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
  
    return (
        <div className="btn-top2">
        {backToTopButtom &&(
            <button style={{
                position: "fixed", 
                bottom:"80px",
                right:"22px",
                height:"50px",
                width:"50px",
                background:"var(--main-color)",
                color:"white",
                fontSize:"40px", 
                zIndex: "99",
                borderRadius:"100%"
            }}
            onClick={scrollUp}
            >^</button>
        )}
    </div>
  )
}
