import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import Form  from "../section-components/form";
import Map  from "../contact-component/map";

const ContactPage = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div className="contact-list pd-top-120 pd-bottom-50">
        <div className="cont-contact">
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <div className="contact-list-inner">
                <div className="media">
                  <div className="media-left">
                  <i className="fa fa-map text-white" style={{fontSize:"30px"}}></i>
                  </div>
                  <div className="media-body align-self-center">
                    <h5>Location</h5>
                    <p>{rpdata?.dbPrincipal?.location[0].address}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="contact-list-inner">
                <div className="media">
                  <div className="media-left">
                  <i className="fa fa-phone text-white" style={{fontSize:"30px"}}></i>
                  </div>
                  <div className="media-body align-self-center">
                    <h5>Our Phone</h5>
                    {rpdata?.dbPrincipal?.phones?.slice(0,1).map((phone, index) => {
                      return (
                        <p key={index}>
                          <a href={`tel:+1${phone.phone}`}>{phone.phone}</a>
                        </p>
                      );
                    })}
                    {/* {rpdata?.dbPrincipal?.phones?.slice(1.1).map((phone, index) => {
                      return (
                        <p key={index}>
                          <a href={`tel:+1${phone.phone}`}>{phone.phone}</a>
                        </p>
                      );
                    })} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="contact-list-inner">
                <div className="media">
                  <div className="media-left">
                  <i className="fa fa-phone text-white" style={{fontSize:"30px"}}></i>
                  </div>
                  <div className="media-body align-self-center">
                    <h5>Our Phone</h5>
                    {/* {rpdata?.dbPrincipal?.phones?.slice(0,1).map((phone, index) => {
                      return (
                        <p key={index}>
                          <a href={`tel:+1${phone.phone}`}>{phone.phone}</a>
                        </p>
                      );
                    })} */}
                    {rpdata?.dbPrincipal?.phones?.slice(1.1).map((phone, index) => {
                      return (
                        <p key={index}>
                          <a href={`tel:+1${phone.phone}`}>{phone.phone}</a>
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="contact-list-inner">
                <div className="media">
                  <div className="media-left">
                    <i className="fa fa-calendar text-white" style={{fontSize:"25px"}}></i>
                  </div>
                  <div className="media-body align-self-center">
                    <h5>{rpdata?.dbPrincipal?.workdays[0].day}</h5>
                    <p>{rpdata?.dbPrincipal?.workHours[0].hour}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter area start */}
      <div className="counter-area pd-bottom-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="center-cont">
              
              <Map/>
              
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="form-cont">
              <h3 className="sub-title text-center">- Get in touch -</h3>
                  <Form/>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      {/* counter area end */}
      {/* contact area start */}
    </div>
  );
};

export default ContactPage;
